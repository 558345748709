var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h2", [_vm._v(_vm._s(_vm.title))]),
      _c(
        "app-form",
        {
          attrs: {
            id: "user-form",
            submitAction: _vm.edit,
            submitSuccessAction: _vm.editSuccess,
            validationOptions: _vm.validationOptions
          }
        },
        [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-textbox", {
                attrs: {
                  id: "email",
                  placeholder: _vm.$t("shared.email"),
                  enabled: false
                },
                model: {
                  value: _vm.user.email,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "email", $$v)
                  },
                  expression: "user.email"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-textbox", {
                attrs: { id: "firstName", placeholder: _vm.$t("shared.name") },
                model: {
                  value: _vm.user.firstName,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "firstName", $$v)
                  },
                  expression: "user.firstName"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-textbox", {
                attrs: {
                  id: "lastName",
                  placeholder: _vm.$t("shared.surname")
                },
                model: {
                  value: _vm.user.lastName,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "lastName", $$v)
                  },
                  expression: "user.lastName"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-dropdownlist", {
                attrs: {
                  id: "roleId",
                  placeholder: _vm.$t("shared.role"),
                  dataSource: _vm.roles,
                  fields: { text: "name", value: "id" }
                },
                model: {
                  value: _vm.user.roleId,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "roleId", $$v)
                  },
                  expression: "user.roleId"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-dropdownlist", {
                attrs: {
                  id: "employeeId",
                  placeholder: _vm.$t("employees.employee"),
                  dataSource: _vm.employees,
                  fields: { text: "fullName", value: "id" }
                },
                model: {
                  value: _vm.user.employeeId,
                  callback: function($$v) {
                    _vm.$set(_vm.user, "employeeId", $$v)
                  },
                  expression: "user.employeeId"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showOfficesGrid,
                  expression: "showOfficesGrid"
                }
              ],
              staticClass: "form-group"
            },
            [
              _c("h4", { staticStyle: { margin: "12px 0 6px" } }, [
                _vm._v(" " + _vm._s(_vm.$t("offices.offices")) + " ")
              ]),
              _c(
                "ejs-grid",
                {
                  ref: "userOfficesGrid",
                  attrs: {
                    allowSorting: true,
                    dataSource: _vm.user.offices,
                    editSettings: _vm.officesGrid.editSettings,
                    sortSettings: _vm.officesGrid.sortOptions,
                    toolbar: _vm.officesGrid.toolbar,
                    actionBegin: _vm.officesGridActionBegin,
                    actionComplete: _vm.officesGridActionComplete
                  }
                },
                [
                  _c(
                    "e-columns",
                    [
                      _c("e-column", {
                        attrs: {
                          field: "officeId",
                          headerText: _vm.$t("shared.id"),
                          isPrimaryKey: true,
                          width: "0",
                          validationRules:
                            _vm.officesGrid.validationOptions.rules.officeId,
                          type: "number"
                        }
                      }),
                      _c("e-column", {
                        attrs: {
                          field: "officeName",
                          headerText: _vm.$t("offices.office"),
                          width: "200"
                        }
                      }),
                      _c("e-column", {
                        attrs: {
                          width: "90",
                          commands: _vm.officesGrid.commands
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("app-form-buttons", {
            attrs: {
              addSubmitButton: true,
              submitButtonText: "shared.edit",
              addCancelButton: true,
              cancelAction: _vm.cancel
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }